<template>
  <div>
    <h2 v-if="drafts.length > 0">Pending draft :</h2>
    <div v-for="draft in drafts" :key="draft._id">
      <div class="document" v-if="draft.draft">
        <span class="document-name"
          >{{ draft.name }} <span class="draft">Draft</span></span
        >
        <br />
        <div>
          <span class="span-title">Created by: </span>
          <span class="span-content">{{
            draft.created_by.surname + " " + draft.created_by.name
          }}</span>
          <span class="span-title">Creation date:</span>
          <span class="span-content">{{
            new Date(draft.creation_date).toDateString()
          }}</span>
        </div>
        <button class="edit-button" v-on:click="editDocument(draft._id)">
          Edit document
        </button>
        <button class="delete-button" v-on:click="deleteDocument(draft._id)">
          Delete document
        </button>
      </div>
    </div>
    <h2>Documents</h2>
    <input
      id="search-document"
      type="text"
      placeholder="Search a document..."
      v-model="searchQuery"
      v-on:keypress.enter="searchDocument"
    />
    <button id="search-button" v-on:click="searchDocument" v-if="!searching">
      Search
    </button>
    <button id="cancel-search" v-on:click="cancelSearch" v-if="searching">
      Cancel search
    </button>
    <button
      id="create-document"
      v-on:click="$router.push({ name: 'new-document' })"
    >
      New document
    </button>
    <div class="no-docs">
      <span v-if="!documents.length">We don't find any document !</span>
    </div>
    <div v-for="document in documents" :key="document._id">
      <div v-if="!document.draft" class="document">
        <span class="document-name">{{ document.name }}</span>
        <br />
        <div>
          <span class="span-title">Created by: </span>
          <span class="span-content">{{
            document.created_by.surname + " " + document.created_by.name
          }}</span>
          <span class="span-title">Creation date:</span>
          <span class="span-content">{{
            new Date(document.creation_date).toDateString()
          }}</span>
          <span class="span-title" v-if="document.edited_by.length !== 0"
            >Last edited by:</span
          >
          <span class="span-content" v-if="document.edited_by.length !== 0">{{
            document.edited_by[document.edited_by.length - 1].user.surname +
            " " +
            document.edited_by[document.edited_by.length - 1].user.name
          }}</span>
          <span class="span-title" v-if="document.edited_by.length !== 0"
            >Last edition date:</span
          >
          <span class="span-content" v-if="document.edited_by.length !== 0">{{
            new Date(
              document.edited_by[document.edited_by.length - 1].date
            ).toDateString()
          }}</span>
        </div>
        <button class="view-button" v-on:click="openDocument(document._id)">
          View document
        </button>
        <button class="edit-button" v-on:click="editDocument(document._id)">
          Edit document
        </button>
        <button class="delete-button" v-on:click="deleteDocument(document._id)">
          Delete document
        </button>
      </div>
    </div>
    <Document
      v-bind:document_id="this.$store.state.documentDisplayId"
      v-if="this.$store.state.documentDisplay"
    ></Document>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";
import Document from "./Document";

export default {
  name: "DocumentList",
  components: { Document },
  data() {
    return {
      documents: [],
      drafts: [],
      searchQuery: "",
      searching: false,
    };
  },
  async mounted() {
    await this.getDocuments();
  },
  methods: {
    getDocuments: async function () {
      try {
        const docs = await HandleApi.getDocumentList();
        for (const doc of docs) {
          if (!doc.draft) {
            this.documents.push(doc);
          } else if (doc.draft) {
            this.drafts.push(doc);
          }
        }
      } catch (err) {
        return err;
      }
    },
    editDocument: function (id) {
      this.$router.push({ name: "edit-document", params: { id } });
    },
    openDocument: function (id) {
      this.$store.state.documentDisplayId = id;
      this.$store.state.documentDisplay = true;
    },
    deleteDocument: async function (id) {
      try {
        await HandleApi.deleteDocument(id);
        this.$router.go(0);
      } catch (err) {
        return err;
      }
    },
    searchDocument: async function () {
      const keyword = this.searchQuery.trim();
      try {
        this.documents = await HandleApi.searchDocument(keyword);
        this.searching = true;
      } catch (err) {
        return err;
      }
    },
    cancelSearch: function () {
      this.searching = false;
      this.searchQuery = "";
      this.getDocuments();
    },
  },
};
</script>

<style scoped>
h2 {
  margin-left: 5px;
}
.document {
  margin: 5px 20px;
  width: 80%;
  border: 1px solid rgb(240, 240, 240);
  border-radius: 10px;
  padding: 10px;
  box-shadow: 2px 2px 2px lightgray;
}
.document-name {
  font-size: 24px;
  font-weight: bold;
}
.draft {
  color: lightgray;
  font-style: italic;
}
.no-docs {
  margin-top: 15px;
  display: flex;
  justify-content: center;
}
.no-docs > span {
  font-size: 25px;
  font-weight: bold;
}
.span-title {
  margin-right: 5px;
}
.span-content {
  margin-right: 30px;
  font-weight: bold;
}
#search-button {
  margin-top: 5px;
  margin-right: 5px;
  background: white;
  padding: 5px 10px;
  border: solid 1px rgb(240, 240, 240);
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}
#cancel-search {
  margin-top: 5px;
  margin-right: 5px;
  background: #ff3a3a;
  color: white;
  padding: 5px 10px;
  border: none;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
}
#search-document {
  margin-right: 5px;
  padding: 5px 10px;
  border-radius: 40px;
  border: 1px solid rgb(240, 240, 240);
  width: 70%;
  font-size: 20px;
}
#create-document {
  margin-top: 5px;
  margin-right: 5px;
  background: #9ad3d1;
  padding: 5px 10px;
  border: none;
  border-radius: 40px;
  font-size: 20px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.edit-button,
.view-button,
.delete-button {
  margin-top: 5px;
  margin-right: 5px;
  background: #9ad3d1;
  padding: 5px 10px;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
.delete-button {
  background: #ff3a3a;
}
#search-document:focus,
.edit-button:focus,
.view-button:focus,
.delete-button:focus,
#create-document:focus,
#search-button:focus,
#cancel-search:focus {
  outline: none;
}
</style>
