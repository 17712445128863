<template>
  <div>
    <div class="backdrop"></div>
    <div class="container">
      <div class="modal">
        <div class="modal-header">
          <h1>{{ document.name }}</h1>
        </div>
        <div class="modal-content" v-html="document.content"></div>
        <div class="modal-footer">
          <button v-on:click="closeDocument">Close</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import HandleApi from "../apiHandle";

export default {
  name: "Document",
  props: {
    document_id: {
      type: String,
    },
  },
  data() {
    return {
      document: {},
    };
  },
  async created() {
    this.getContent();
  },
  methods: {
    closeDocument: function () {
      this.$store.state.documentDisplayId = "";
      this.$store.state.documentDisplay = false;
    },
    getContent: async function () {
      try {
        this.document = await HandleApi.getDocument(this.document_id);
      } catch (err) {
        return err;
      }
    },
  },
};
</script>

<style scoped>
.backdrop {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.3);

  z-index: 1;
}
.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 98;
}
.modal {
  width: 90%;
  height: 80%;
  padding: 30px;
  border-radius: 5px;
  background: #fff;
  box-shadow: 2px 2px 20px 1px;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
  z-index: 99;
}
.modal-header {
  display: flex;
  justify-content: center;
}
.modal-content {
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  padding: 10px 100px;
  background: #fafafa;
}
.modal-footer {
  display: flex;
  justify-content: center;
}
.modal-footer button {
  margin-top: 10px;
  background: #9ad3d1;
  padding: 5px 10px;
  border: none;
  border-radius: 40px;
  font-size: 16px;
  font-weight: bold;
  color: white;
  cursor: pointer;
}
</style>
